(function () {
  angular.module('kmi.lms.user').config(function ($stateProvider) {
    $stateProvider
      .state('prompt.userRegistration', {
        url: '/create-account',
        component: 'userRegistration',
        data: {
          label: 'Create Account',
          availableFor: ['anonymous'],
        },
      })
      .state('prompt.userRegistration.policies', {
        url: '/policies',
        component: 'policiesComponent',
        data: {
          label: 'Policies',
          availableFor: ['anonymous', 'regularUser', 'admin'],
        },
      })
      .state('edit.account', {
        url: '/account?activeView',
        component: 'editUserAccount',
        params: {
          activeView: null,
        },
        data: {
          label: 'Account edit',
          availableFor: ['regularUser', 'admin'],
        },
        resolve: {
          user: function (currentUser, User, customUserAttributesService) {
            return User.get().then(function (user) {
              var updatedUser = customUserAttributesService.loadCustomAttributes(user);
              currentUser.set(updatedUser);
              return updatedUser;
            });
          },
        },
      });
  });
})();
