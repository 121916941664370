import {
  configureApplicationRuntimeSettings,
  configureApplicationSettings,
} from '../../modules/app/configuration/application-configuration';

require('ajs/vendors');
require('ajs/modules/app/models/class.ts');
require('ajs/global-dependencies.ts');
require('ajs/global-scope.ts');
require('ajs/modules/app/_init.ts');

declare const angular: angular.IAngularStatic;

angular
  .module('elmsSpaApp', [
    'ui.router',
    'ui.router.upgrade',
    'http-auth-interceptor',
    'ui.bootstrap',
    'angular-ladda',
    'ngCookies',
    'ngAnimate',
    'ngResource',
    'ui.mask',
    'ngSanitize',
    'ngAria',
    'globalDependencies',
    'kmi.lms.services',
    'blueimp.fileupload',
    'kmi.lms.core',
    'kmi.lms.core.login',
    'kmi.lms.components',
    'kmi.lms.home',
    'kmi.lms.user.certificates',
    'kmi.lms.search',
    'kmi.lms.user',
    'kmi.lms.user.notifications',
    'kmi.lms.quiz',
    'kmi.lms.course.quizzes',
    'kmi.lms.course.common',
    'kmi.lms.course.details',
    'kmi.lms.course.registrations',
    'kmi.lms.user.learning',
    'kmi.lms.user.achievements',
    'kmi.lms.course.posts',
    'kmi.lms.course.custom-list',
    'kmi.lms.user.network',
    'kmi.lms.subjectAreas',
    'kmi.lms.user.registration',
    'kmi.lms.dictionary',
    'kmi.lms.passwords',
    'kmi.lms.course.purchase',
  ])
  .config(configureApplicationSettings)
  .config(moduleConfig)
  .run(configureApplicationRuntimeSettings);

require('ajs/core-modules');
require('ajs/custom_modules/demo/_init.ts');
require('ajs/custom_modules/demo/search/_init.ts');
require('ajs/custom_modules/demo/user/user-config.ts');
require('ajs/custom_modules/demo/user/learning/_init.ts');
require('ajs/modules/user/notifications/grouped/notifications-bell-directive.ts');
require('ajs/modules/user/notifications/grouped/notifications-list-component.ts');
require('ajs/modules/user/notifications/grouped/notifications-alert-component.ts');
require('ajs/modules/user/notifications/grouped/modal/notifications-component.ts');

require('src/projects/demo/index.module.ts');

function moduleConfig($stateProvider) {
  $stateProvider.state('main.home', {
    url: '^/home?state',
    component: 'generalHome',
    params: {
      state: null,
    },
    data: {
      label: 'Home',
      availableFor: ['regularUser', 'admin'],
    },
  });
}
